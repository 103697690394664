import React, { useState } from 'react'
import qs from 'qs'
import axios from 'axios'
import publicIp from 'public-ip'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'
import { Spinner } from '@styled-icons/fa-solid'
import { navigate } from 'gatsby'
import { useFormik } from 'formik'
import { useLocation } from '@gatsbyjs/reach-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { get, replace, isEqual } from 'lodash'
import styled, { keyframes } from 'styled-components'
import * as Yup from 'yup'

import media from '../../../utils/media'
import FormItems from '../../atoms/FormItems'
import FormError from '../../atoms/FormError'
import SubmitButton from '../../atoms/SubmitButton'
import { PHONE_REGEXP, API_SALES_URL } from '../../../config'

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const SpinningIcon = styled(Spinner)`
  margin-right: 0.25rem;
  animation: 2s linear ${spin} infinite;
`

const StyledIcon = styled(FontAwesomeIcon)`
  margin-left: 16px;

  ${media.lessThan('lg')`
    margin-left: 8px;
  `}
`

const formSchema = Yup.object().shape({
  phone: Yup.string().required('Votre numéro de téléphone est requis').matches(PHONE_REGEXP, 'Votre téléphone n\'est pas au bon format'),
  email: Yup.string().email('Votre email n\'est pas au bon format').required('Votre email est requis'),
  consent: Yup.bool().oneOf([true], 'Veuillez accepter d\'être contacté par TrouverMonLocalPro'),
  message: Yup.string().required('Un message est requis'),
  lastname: Yup.string().required('Votre nom est requis'),
  firstname: Yup.string().required('Votre prénom est requis'),
  occupation: Yup.string().required('Votre profession est requise')
})

const ITEMS = [{
  type: 'text',
  name: 'firstname',
  placeholder: 'Prénom'
}, {
  type: 'text',
  name: 'lastname',
  placeholder: 'Nom'
}, {
  type: 'text',
  name: 'occupation',
  placeholder: 'Profession'
}, {
  type: 'text',
  name: 'phone',
  placeholder: 'N° de téléphone'
}, {
  type: 'email',
  name: 'email',
  placeholder: 'Email'
}, {
  type: 'textarea',
  name: 'message',
  placeholder: 'Message à destination du propriétaire'
}]

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1px;
  max-width: 365px;

  ${media.greaterThan('xxl')`
    gap: 6px;
  `}
`

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: flex-start;
`

const CheckBox = styled.div`
  width: ${({ isOk }) => isOk ? '23' : '35'}px;
  height: ${({ isOk }) => isOk ? '20' : '20'}px;
  margin-top: 0.4rem;
  margin-right: 0.5rem;
  border-radius: 2px;
  padding: 2px;
  border: 1px solid ${({ theme }) => get(theme, 'blue', '#FFF')};
  background-color: ${({ isOk, theme }) => get(theme, isOk ? 'blue' : 'white', '#FFF')};
  cursor: pointer;
`

const CheckText = styled.label`
  font-family: 'Source Sans Pro';
  font-size: 14px;
  line-height: 1.43;
  color: ${({ theme, isWhite }) => get(theme, isWhite ? 'white' : 'blue')};
`

const StyledSubmitButton = styled(SubmitButton)`
  margin: 4px auto;
  width: fit-content;
  min-height: 36px;
  padding: 5px 28px;

  ${media.greaterThan('xxl')`
    min-height: 48px;
    width: 75%;
    margin: 8px auto;
  `}
`

const getClientIp = async () => await publicIp.v4({
  fallbackUrls: ['https://ifconfig.co/ip']
})

const DemandeAdAccompagnementForm = ({ add, disabledAd, submit, gaEventName, hiddenRef, ...props }) => {
  const location = useLocation()
  const [loading, updateLoading] = useState(false)

  const { values, handleSubmit, handleChange, resetForm, setFieldValue, setFieldTouched, errors, touched, handleBlur } = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      phone: '',
      consent: false,
      message: '',
      lastname: '',
      firstname: '',
      occupation: ''
    },
    validationSchema: formSchema,
    onSubmit: async values => {
      if (loading) {
        return
      }

      try {
        updateLoading(true)
        const eventId = nanoid()
        const time = Math.floor(Date.now() / 1000)
        const userAgent = navigator.userAgent
        const userIp = await getClientIp()
        const isFacebook = !!get(qs.parse(replace(get(location, 'search'), '?', '')), 'facebook')

        await axios.get(submit, {
          params: {
            ...add,
            ...values,
            time,
            userIp,
            eventId,
            userAgent,
            isFacebook,
            eventSourceUrl: get(location, 'href')
          }
        })

        await axios.post(`${API_SALES_URL}/api/users/request_ad`, {
          user: {
            email: get(values, 'email', ''),
            message: get(values, 'message', ''),
            last_name: get(values, 'lastname', ''),
            first_name: get(values, 'firstname', ''),
            program_id: get(add, 'programId', ''),
            phone_number: get(values, 'phone', '')
          }
        })

        resetForm()
        if (process.env.NODE_ENV !== 'production') {
          return null
        }

        if (typeof gtag === 'function') {
          window.gtag('event', gaEventName, { send: true })
        }

        navigate('/nos-annonces/ad/success')
      } catch (err) {
        console.log(err)
      } finally {
        updateLoading(false)
      }
    }
  })

  return (
    <StyledForm onSubmit={handleSubmit} id='sideForm' {...props}>
      <FormItems
        small={true}
        items={ITEMS}
        width={true}
        disabled={disabledAd}
        errors={errors}
        values={values}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue} />
      <CheckBoxContainer
        onClick={() => {
          if (!disabledAd) {
            setFieldValue('consent', !get(values, 'consent'))
            setFieldTouched('consent', true)
          }
        }}>
        <CheckBox
          id='consent-checkbox'
          isOk={get(values, 'consent')}>
          {get(values, 'consent', false) && <FontAwesomeIcon icon='check' color='white' />}
        </CheckBox>
        <CheckText htmlFor='consent-checkbox'>
          J’accepte de transmettre mes coordonnées au propriétaire de cette annonce afin qu&apos;il puisse me joindre
        </CheckText>
      </CheckBoxContainer>
      {!get(values, 'consent') && get(errors, 'consent') && get(touched, 'consent') &&
        <FormError error={get(errors, 'consent')} />}
      <StyledSubmitButton
        ref={hiddenRef}
        type='submit'
        disabled={loading || disabledAd}>
        {loading &&
          <SpinningIcon
            size={20}
            role='status' />}
        Envoyer <StyledIcon icon='chevron-right' />
      </StyledSubmitButton>
    </StyledForm >
  )
}

DemandeAdAccompagnementForm.propTypes = {
  add: PropTypes.object,
  submit: PropTypes.string.isRequired,
  hiddenRef: PropTypes.object.isRequired,
  gaEventName: PropTypes.string.isRequired
}

export default DemandeAdAccompagnementForm
