import React, { useRef, useState, useCallback } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { useSwipeable } from 'react-swipeable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { get, isEmpty, isEqual } from 'lodash'

import theme from '../../../config/theme'
import media from '../../../utils/media'
import SubmitButton from '../../atoms/SubmitButton'
import useWindowPathname from '../../../utils/useWindowPathname'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'
import DemandeAdAccompagnementForm from '../DemandeAdAccompagnementForm'
import { useFetchMeQuery } from '../../../queries/users'

const AD_FORM_ZAPIER = 'https://hooks.zapier.com/hooks/catch/2114713/3som299/'

const HeaderTitle = styled.h2`
  font-family: 'Source Sans Pro';
  font-size: 26px;
  margin: 0;
  font-weight: bold;
  color: ${({ theme }) => get(theme, 'brightOrange', '#fff')};

  ${media.lessThan('md')`
    font-size: 20px;
  `}

  ${media.lessThan('sm')`
    font-size: 18px;
  `}

  ${media.greaterThan('xxl')`
    font-size: 36px;
  `}
`

const BaseContainer = styled.div`
  ${media.lessThan('md')`
    ${({ hideMobile }) => hideMobile ? 'display: none;' : ''};
  `}

  ${media.lessThan('xl')`
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
    background-color: ${({ theme }) => get(theme, 'white', '#fff')};
  `}
`

const IconContainer = styled.div`
  display: none;

  ${media.lessThan('xl')`
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`

const Icon = styled(FontAwesomeIcon)`
  font-size: 40px;
  transition: transform 330ms ease-in-out;
  ${({ show }) => show ? 'transform: rotate(180deg);' : ''}
`

const Container = styled.div`
  width: 300px;
  height: auto;
  display: flex;
  position: sticky;
  flex-direction: column;
  padding: 0 24px;
  top: 4rem;

  ${media.lessThan('xl')`
    width: 100%;
    margin-left: 0;
    padding-top: 10px;
    border-radius: 0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  `}
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;

  ${media.lessThan('xl')`
    justify-content: center;
  `}
`

const Title = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 23px;
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: 0.69px;
  color: ${({ theme }) => get(theme, 'brightOrange', '#fff')};

  ${media.lessThan('sm')`
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4;
  `}

  & > span {
    font-size: 40px;
    font-weight: 600;
    line-height: 0.75;
    letter-spacing: 4.4px;
    color: ${({ theme }) => get(theme, 'lightOrange', '#fff')};

    ${media.lessThan('xl')`
      font-size: 30px;
      line-height: 1;
      letter-spacing: 3.3px;
    `}
  }
`

const DetailContainer = styled.div`
  margin-top: 8px;

  ${media.lessThan('xl')`
    display: flex;
    justify-content: center;
    overflow-y: hidden;
    transition: all 500ms ease-in-out;
    max-height: ${({ show }) => show ? '90vh' : '0'};
  `}
`
const ErrorButton = styled(SubmitButton)`
  width: 300px;
  align-self: center;
`
const ErrorContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  margin: 0 10%;
`

const CityFormNotToggle = () => {
  return (
    <Container>
      <TitleContainer>
        <Title>
          Contacter
        </Title>
      </TitleContainer>
      <DetailContainer>
        <DemandeAdAccompagnementForm submit='' />
      </DetailContainer>
    </Container>
  )
}

const AdType = {
  sale: 'vendre',
  rental: 'louer',
  sublet: 'sous-louer'
}

const CityFormToggle = ({ loading, ad, hideMobile }) => {
  const [show, updateShow] = useState(false)
  const hiddenRef = useRef()
  const disabled = isEqual(get(ad, 'status'), 3)

  const toggleShow = useCallback(() => updateShow(!show), [updateShow, show])
  const handlers = useSwipeable({
    onSwipeUp: () => updateShow(true),
    onSwipeDown: () => updateShow(false),
    preventDefaultTouchmoveEvent: true
  })

  if (loading || isEmpty(ad)) {
    return null
  }

  return (
    <BaseContainer hideMobile={hideMobile}>
      <Container>
        <IconContainer
          onClick={toggleShow}
          {...handlers}>
          <Icon show={show} icon='angle-up' color={get(theme, 'blue')} />
        </IconContainer>
        <TitleContainer onClick={toggleShow} {...handlers}>
          <HeaderTitle>
            Contacter
          </HeaderTitle>
          {disabled && <HomeSectionDescription color='grey'>Cette annonce n'est plus disponible</HomeSectionDescription>}
        </TitleContainer>
        <DetailContainer show={show}>
          <DemandeAdAccompagnementForm
            disabledAd={disabled}
            submit={AD_FORM_ZAPIER}
            hiddenRef={hiddenRef}
            gaEventName='envoi_formulaire_demande_information_programme'
            add={{
              demandeType: 'annonce',
              programId: get(ad, 'id'),
              programName: get(ad, 'name'),
              programPrice: get(ad, 'min_price'),
              programCity: get(ad, 'display_city'),
              programType: AdType[get(ad, 'adType')],
              programZipcode: get(ad, 'zipcode'),
              programRooms: get(ad, 'rooms'),
              programSurface: get(ad, 'surface'),
              programFloor: get(ad, 'floor'),
              programUser: get(ad, 'user')
            }} />
        </DetailContainer>
      </Container>
    </BaseContainer>
  )
}

CityFormToggle.propTypes = {
  ad: PropTypes.object,
  form: PropTypes.string,
  city: PropTypes.string,
  phone: PropTypes.string,
  submit: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  hideMobile: PropTypes.bool
}

CityFormToggle.defaultProps = {
  city: '',
  programs: [],
  hideMobile: false
}

CityFormToggle.defaultProps = {
  form: 'recherche ville',
  phone: '',
  submit: '',
  program: {}
}

const AdForm = ({ withToggle, ...props }) => {
  if (withToggle) {
    return (
      <CityFormToggle {...props} />
    )
  }

  return (
    <CityFormNotToggle {...props} />
  )
}

AdForm.propTypes = {
  withToggle: PropTypes.bool
}

AdForm.defaultProps = {
  withToggle: true
}

export default AdForm
