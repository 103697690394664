import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get, isEqual, map, isEmpty } from 'lodash'

import media from '../../../utils/media'
import AdForm from '../AdForm'
import AdData from '../../molecules/AdData'
import ProgramMap from '../../molecules/ProgramMap'
import ProgramTitle from '../../molecules/ProgramTitle'
import AdHeaderData from '../../molecules/AdHeaderData'
import LoadingProgram from '../../atoms/LoadingProgram'
import ProgramCarousel from '../../molecules/ProgramCarousel'
import ProgramDescription from '../../molecules/ProgramDescription'

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  padding: 24px 0 56px 0;
  justify-content: center;
  ${({ disabled }) => disabled ? 'filter: grayscale(80%)': ''};

  ${media.lessThan('xl')`
    flex-direction: column;
    align-items: center;
    // padding: 20px 8px;
  `}
`

const AdContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 54px;

  ${media.lessThan('xl')`
    margin: 0;
    width: 100%;
  `}
`

const ItemContainer = styled.div`
  padding: 0 24px;

  ${media.lessThan('xl')`
    &:first-of-type {
      padding-top: 64px;
    }
  `}
`

const DesktopItemContainer = styled(ItemContainer)`
  display: block;

  ${media.lessThan('xl')`
    display: none;
  `}
`

const MobileItemContainer = styled(ItemContainer)`
  display: none;

  ${media.lessThan('xl')`
    margin-top: 24px;
    display: block;
  `}
`

const ContentContainer = styled.div`
    width: 800px;
    margin-top: 40px;

    ${media.lessThan('sm')`
      margin-top: 0;
    `}
`

const StyledAdHeaderData = styled(AdHeaderData)`
    margin-left: 8px;
`

const AdContent = ({ loading, ad }) => {
  if (loading) {
    return (
      <LoadingProgram loading={loading} />
    )
  }

  const city = {
    name: get(ad, 'display_city'),
    zipcode: get(ad, 'zipcode')
  }
  const name = get(ad, 'name')

  return (
    <ContentContainer>
      <DesktopItemContainer>
        <ProgramTitle
          city={city}
          name={name} />
      </DesktopItemContainer>
      <ProgramCarousel type='ad' imgs={map(get(ad, 'images'), 'url')} />
      <StyledAdHeaderData ad={ad} />
      <MobileItemContainer>
        <ProgramTitle
          city={city}
          name={name} />
      </MobileItemContainer>
      <AdData
        ad={ad} />
      {!isEmpty(get(ad, 'description')) &&
        <ItemContainer>
          <ProgramDescription description={get(ad, 'description', '')} />
        </ItemContainer>}
      <ItemContainer>
        <ProgramMap
          city={city}
          address={{
            lat: get(ad, 'lat'),
            lng: get(ad, 'lng'),
            label: get(ad, 'address')
          }}
          addressText={get(ad, 'address')} />
      </ItemContainer>
    </ContentContainer>
  )
}

AdContent.propTypes = {
  ad: PropTypes.object,
  loading: PropTypes.bool.isRequired
}

const AdPage = ({ ad, loading }) => {
  return (
    <Container disabled={isEqual(get(ad, 'status'), 3)}>
      <AdContentContainer>
        <AdContent ad={ad} loading={loading} />
        <AdForm
          ad={ad}
          loading={loading} />
      </AdContentContainer>
    </Container>
  )
}

AdPage.propTypes = {
  ad: PropTypes.object,
  loading: PropTypes.bool.isRequired
}

export default AdPage
