import React, { useState, useCallback, useEffect } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { deserialize } from 'deserialize-json-api'
import { get, isEqual, isEmpty } from 'lodash'

import SEO from '../../atoms/Seo'
import Navbar from '../../organisms/Navbar'
import Footer from '../../organisms/Footer'
import AdPage from '../../organisms/AdPage'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import { API_SALES_URL } from '../../../config'

const Title = styled(HomeSectionTitle)`
  text-align: center;
  margin: 108px 0 74px 0;
`

const AdTemplate = ({ id, location }) => {
  const [ad, updateAd] = useState(null)
  const [error, setError] = useState('')
  const [loading, updateLoading] = useState(false)

  const fetchAd = useCallback(async () => {
    updateLoading(true)

    try {
      const { data } = await axios.get(`${API_SALES_URL}/api/annonces/${id}`)

      updateAd(get(deserialize(data), 'data'))
    } catch (err) {
      const response = get(err, 'response.data.message', '')
      if (isEqual(response, 'Ad archived')) {
        setError('Annonce supprimée')
      } else {
        setError('Impossible de charger cette annonce.')
      }
    } finally {
      updateLoading(false)
    }
  }, [updateLoading, updateAd])

  useEffect(() => {
    fetchAd()
  }, [])

  const title = 'Annonce de TrouverMonLocalPro'
  const description = 'TrouverMonLocalPro vous propose en exclusivité les meilleurs programmes neufs dans toutes la France'

  return (
    <>
      <SEO
        title={title}
        location={location}
        description={description}>
        </SEO>
      <Navbar location={location} />
      {isEmpty(error) && <AdPage loading={loading} ad={ad} />}
      {!isEmpty(error) && <Title>{error}</Title>}
      <Footer />
    </>
  )
}

AdTemplate.propTypes = {
  id: PropTypes.string.isRequired,
  location: PropTypes.object
}

export default AdTemplate
