import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { StaticImage } from 'gatsby-plugin-image'
import { get, isEqual, split, includes, map, isEmpty } from 'lodash'

import media from '../../../utils/media'
import numeral from '../../../config/number'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => get(theme, 'blue')};
  margin-top: 26px;
  padding: 24px 16px;
  border-radius: 10px;
  flex-direction: column;
  gap: 24px;

  ${media.lessThan('md')`
    margin: 26px 16px 0 16px;
  `}

  ${media.lessThan('sm')`
    padding: 8px;
    flex-direction: column;
    justify-content: center;
  `}
`

const DataPart = styled.div`
  display: flex;
  align-items: center;
  margin-right: 28px;

  ${media.lessThan('sm')`
    margin-top: 16px;
    margin-right: 0;
  `}
`

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 8px;
`

const Inline = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
  align-items: center;
`
const InlineWrap = styled(Inline)`
  flex-wrap: wrap;
`

const Day = styled.div`
  padding: 4px;
  border: 1px solid ${({ theme }) => get(theme, 'blue')};
  border-radius: 5px;
`

const AdData = ({ ad }) => {
  const price = numeral(get(ad, 'min_price', 0)).format('0,0 $')
  const extras = split(get(ad, 'extras'), ',')
  const regex = /parking (mutualisé|privatif)/i
  const parking = regex.test(extras)
  const labelRoom = get(ad, 'rooms') > 1 ? 'pièces' : 'pièce'
  const labelFloor = isEqual(get(ad, 'floor'), 0) ? 'RDC' : `étage ${get(ad, 'floor')}`

  const currentDate = new Date()
  const availabilityDate = new Date(get(ad, 'availability'))
  const isPast = currentDate > availabilityDate
  const formattedDate = availabilityDate.toLocaleDateString('fr-FR', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })

  return (
    <Container>
      <Inline>
        <DataPart>
          <ImageContainer>
            <StaticImage style={{ width: '30px', height: '30px' }} src='../../../images/picto/revenufiscal.png' alt='surface programme' />
          </ImageContainer>
          <Inline>
            <HomeSectionDescription>{isEqual(get(ad, 'adType'), 'sale') ? price : `Loyer de ${price} / mois`}</HomeSectionDescription>
            <HomeSectionDescription>•</HomeSectionDescription>
            <HomeSectionDescription>Charges mensuelles de {numeral(get(ad, 'monthly_charge', 0)).format('0,0 $')}</HomeSectionDescription>
            <HomeSectionDescription>•</HomeSectionDescription>
            {isEqual(get(ad, 'adType'), 'sale') && get(ad, 'property_tax') > 0 && <HomeSectionDescription>Taxe foncière de {numeral(get(ad, 'property_tax', 0)).format('0,0 $')}</HomeSectionDescription>}
          </Inline>
        </DataPart>
      </Inline>
      {includes(['sublet', 'rental'], get(ad, 'adType')) && <Inline>
        <HomeSectionDescription>Disponible {isPast ? 'immédiatement' : `à partir du ${formattedDate}`}</HomeSectionDescription>
      </Inline>}
      {!isEmpty(get(ad, 'days')) &&
        <Inline>
          <HomeSectionDescription>Louable en temps partiel le </HomeSectionDescription>
          {map(split(get(ad, 'days'), ','), day => {
            return (
              <Day>
                <HomeSectionDescription>{day}</HomeSectionDescription>
              </Day>
            )
          })}
        </Inline>}
      <Inline>
        <DataPart>
          <HomeSectionDescription>{get(ad, 'whole') ? 'Local entier' : 'Salle privative'}</HomeSectionDescription>
        </DataPart>
        <DataPart>
          <ImageContainer>
            <StaticImage src='../../../images/picto/surface.png' alt='surface programme' />
          </ImageContainer>
          <HomeSectionDescription>{get(ad, 'surface')}m²</HomeSectionDescription>
        </DataPart>
        {get(ad, 'whole') && <DataPart>
          <ImageContainer>
            <StaticImage src='../../../images/picto/surface.png' alt='surface programme' />
          </ImageContainer>
          <HomeSectionDescription>{get(ad, 'rooms')} {labelRoom}</HomeSectionDescription>
        </DataPart>}
        <DataPart>
          <ImageContainer>
            <StaticImage src='../../../images/picto/floor.png' alt='etage programme' />
          </ImageContainer>
          <HomeSectionDescription>{labelFloor}</HomeSectionDescription>
        </DataPart>
        {parking && <DataPart>
          <ImageContainer>
            <StaticImage style={{ width: '40px', height: '40px' }} src='../../../images/picto/parking.png' alt='livraison programme' />
          </ImageContainer>
          <HomeSectionDescription>Parking</HomeSectionDescription>
        </DataPart>}
      </Inline>
      <HomeSectionDescription>Profession déjà exercée dans ce local : {get(ad, 'profession')}</HomeSectionDescription>
      <InlineWrap>
        {map(extras, (extra, idx) => {
          return (
            <>
              {idx > 0 && <HomeSectionDescription>•</HomeSectionDescription>}
              <HomeSectionDescription>{extra}</HomeSectionDescription>
            </>
          )
        })}
      </InlineWrap>
    </Container>
  )
}
AdData.propTypes = {
  ad: PropTypes.object.isRequired
}

AdData.defaultProps = {
}

export default AdData
