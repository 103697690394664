import React from 'react'
import PropTypes from 'prop-types'

import AdTemplate from '../../../components/templates/AdTemplate'

const AdPage = ({ id, location }) => <AdTemplate id={id} location={location} />

AdPage.propTypes = {
  id: PropTypes.string,
  location: PropTypes.object
}

export default AdPage
