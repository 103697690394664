import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { map, get, split, includes, isEmpty } from 'lodash'

import media from '../../../utils/media'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  margin-top: 8px;

  ${media.lessThan('md')`
    margin-top: 16px;
  `}
`
const Inline = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  ${media.lessThan('md')`
    justify-content: center;
  `}
`

const Tag = styled.div`
  padding: 4px 8px;
  background-color: ${({ theme }) => get(theme, 'blue')};
  border-radius: 4px;
`

const AdHeaderData = ({ ad }, props) => {
  const isRental = includes(['sublet', 'rental'], get(ad, 'adType'))
  const categories = isEmpty(get(ad, 'professions')) ? ['Toutes professions'] : split(get(ad, 'professions'), ',')

  return (
    <Container {...props}>
      {isRental &&
        <Inline>
          <Tag>
            <HomeSectionDescription color='white'>
              {get(ad, 'whole') ? 'Local entier' : 'Salle privative'}
            </HomeSectionDescription>
          </Tag>
          <Tag>
            <HomeSectionDescription color='white'>
              {isEmpty(get(ad, 'days')) ? 'Temps plein' : 'Temps partiel'}
            </HomeSectionDescription>
          </Tag>
          {map(categories, category => {
            return (
              <Tag>
                <HomeSectionDescription color='white'>
                  {category}
                </HomeSectionDescription>
              </Tag>
            )
          })}
        </Inline>}
    </Container>
  )
}
AdHeaderData.propTypes = {
  ad: PropTypes.object.isRequired
}

AdHeaderData.defaultProps = {
}

export default AdHeaderData
